import React, { useState, useRef, useEffect } from 'react';
import { View, Pressable, useSx, ScrollView, useDripsyTheme } from 'dripsy';
import {
  Modal,
  TextInputProps,
  ViewStyle,
  Platform,
  TextStyle,
} from 'react-native';
import { useDim } from 'app/redux/hooks/useDim';
import { Icon } from '../icon/icon';
import { I18Text } from 'app/provider/i18-provider';
interface DropDown extends TextInputProps {
  data: any[] | ((e?: any) => any[]);
  selectedData?: any;
  mainContainerStyle?: ViewStyle | any[];
  styles?: ViewStyle;
  displayKey?: string | ((e: any) => string);
  onSelectData?: (e?: any) => void;
  placeholder?: string;
  iconStyle?: TextStyle;
  containerStyle?: ViewStyle;
  dropDownContainerStyle?: ViewStyle;
  selectTextKey?: string | ((e: any) => string);
  disabled?: boolean;
  clearIcon?: boolean;
  setClearFilter?: (value: boolean) => void;
}

export function DropDown({
  placeholder = 'Select',
  data,
  selectedData,
  displayKey,
  onSelectData,
  mainContainerStyle,
  iconStyle,
  containerStyle,
  selectTextKey,
  dropDownContainerStyle,
  disabled,
  clearIcon,
  setClearFilter,
  ...props
}: DropDown) {
  const { height, width } = useDim();

  const sx = useSx();
  const [touch, setTouch] = useState(false);
  const [widthLayout, setWidthLayout] = useState(0);
  const [selected, setSelected] = useState<any>(null);
  const mainLayout = useRef<any>();
  const [left, setLeft] = useState(0);
  const [list, setList] = useState<any[]>([]);
  const { theme }: any = useDripsyTheme();

  const [dropdownTop, setDropdownTop] = useState(0);
  const isWeb = Platform.OS === 'web';

  useEffect(() => {
    const onScroll = () => {
      setTouch(false);
    };
    // clean up code
    if (typeof window !== 'undefined' && Platform.OS === 'web') {
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    setTouch(false);
  }, [height, width]);

  useEffect(() => {
    //need to be change
    if (data) {
      if (typeof data === 'function') {
        setList([...data()]);
      } else {
        setList([...data]);
      }
    }
  }, [data, displayKey]);

  useEffect(() => {
    if (selectedData) setSelected(selectedData);
  }, [selectedData]);

  const _RenderDataList = () => {
    return list.length > 0 ? (
      <ScrollView
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={true}
        persistentScrollbar={true}
      >
        {list.map((item, i) => (
          <Pressable
            onPress={() => {
              setTouch(!touch);
              //need to change
              setSelected(item);
              //
              if (typeof onSelectData === 'function') onSelectData(item);
            }}
            style={[
              sx({
                padding: 10,
                borderBottomWidth: list.length - 1 === i ? 0 : 0.5,
                marginLeft: 10,
                marginRight: 10,
                borderBottomColor: '$gray',
              }),
            ]}
            key={item?._id || i}
          >
              <I18Text  style={{ fontSize: 12, lineHeight: 16 }}>
              {displayNameForEachItem(item)}
              </I18Text>
          </Pressable>
        ))}
      </ScrollView>
    ) : (
      <View style={{ padding: 10 }}>
        <I18Text style={{ fontSize: 12, lineHeight: 16 }}>No data found</I18Text>
      </View>
    );
  };

  const displayName = () => {
    if (typeof selectTextKey === 'function') {
      return selectTextKey(selected);
    } else if (typeof selectTextKey === 'string') {
      return selected && selected[selectTextKey]
        ? selected[selectTextKey]
        : placeholder;
    } else if (typeof displayKey === 'function') {
      return displayKey(selected);
    } else if (typeof displayKey === 'string') {
      return selected && selected[displayKey]
        ? selected[displayKey]
        : placeholder;
    } else {
      return selected ? selected : placeholder;
    }
  };

  const changeLayout = (w: number) => {
    setWidthLayout(isWeb ? w : width);
    setLeft(left);
  };

  const handleClear = () => {
    setSelected(null);
    if (setClearFilter) {
      setClearFilter(true);
    }
  };

  const openDropdownMainLayout = () => {
    mainLayout.current?.measure(
      (_fx: any, _fy: any, _w: any, h: any, _: any, py: any) => {
        setLeft(_);
        setTouch(!touch);
        setDropdownTop(Platform.OS === 'android' ? py + 15 : py + h - 20);
      }
    );
  };

  const displayNameForEachItem = (item: any) => {
    if (typeof displayKey === 'function') {
      return displayKey(item);
    } else if (typeof displayKey === 'string') {
      return item[displayKey];
    } else {
      return item;
    }
  };

  const _RenderDropDownList = () => {
    return (
      <Modal
        visible={touch}
        transparent={true}
        animationType={isWeb ? 'none' : 'slide'}
        onRequestClose={() => {
          setTouch(!touch);
        }}
      >
        <Pressable disabled={disabled} onPress={() => setTouch(!touch)} style={{ flex: 1 }}>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 22,
            }}
          >
            <View
              style={[
                !isWeb
                  ? {
                    backgroundColor: 'white',
                    borderRadius: 0,
                    alignItems: 'center',
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 0,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 4,
                    elevation: 5,
                    height: height / 2,
                    marginTop: height / 2,
                    width: width,
                  }
                  : sx({
                    width: widthLayout,
                    top: dropdownTop,
                    backgroundColor: '$white',
                    maxHeight: 150,
                    left: left,
                    borderWidth: 0.5,
                    borderRadius: 5,
                    borderColor: '$gray',
                    position: 'absolute',
                    shadowOffset: {
                      width: 0,
                      height: 0,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 4,
                    elevation: 5,
                  }),
                dropDownContainerStyle,
              ]}
            >
              <View style={{ height: '100%', width: '100%', flex: 1 }}>
                {_RenderDataList()}
              </View>
            </View>
          </View>
        </Pressable>
      </Modal>
    );
  };

  return (
    <View
      style={[
        {
          minHeight: 40,
          minWidth: 40,
          flex: 1,
          borderRadius: 5,
          zIndex: 9999,
        },
        mainContainerStyle,
      ]}
      onLayout={(e: any) => {
        changeLayout(e.nativeEvent.layout.width);
      }}
      ref={mainLayout}
    >
      <Pressable
        style={[
          sx({
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#efefef',
            width: '100%',
            zIndex: 1,
            flex: 1,
            borderRadius: 5,
            borderWidth: 0.5,
            borderColor: '$secondary',
          }),
          containerStyle,
        ]}
        onPress={() => {
          openDropdownMainLayout();
        }}
      >
        <View
          style={[sx({
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 1,
            alignItems: 'center',
          }),
          Platform.select({
            native: sx({
              minHeight: 55
            }),
          }),
          ]}
        >
          <I18Text style={[sx({ padding: 10, fontSize: 12 }), props.style]}>
          {displayName()}
          </I18Text>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    {clearIcon && selected?.value && (
      <Pressable
        style={sx({ paddingRight: 2 })}
        onPress={() => handleClear()}
      >
        <Icon
          width={18}
          height={18}
          name="clear"
          fill={['#000']}
        />
      </Pressable>
    )}
    <Pressable
      style={sx({ paddingRight: 2 })}
      onPress={() => openDropdownMainLayout()}
    >
      <Icon
        height={10}
        width={10}
        fill={[theme?.colors['$fontGrey']]}
        name="downArrow"
        style={[
          sx({
            marginTop: Platform.OS === 'web' ? 0 : 1,
            color: '$secondary',
            transform: [{ rotate: touch ? '180deg' : '0deg' }],
          }),
          iconStyle,
        ]}
      />
    </Pressable>
  </View>

        </View>
        <View>{_RenderDropDownList()}</View>
      </Pressable>
    </View>
  );
}
